<template>
<b-card no-body>
    <b-card-header>
        <h3>Company Payroll </h3>
    </b-card-header>

    <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
            <div class="d-flex">
                <v-select class="select_date mb-1 mr-2" v-model="selectedYear" @input="onLoadTable" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="years" />
                <v-select class="select_date mb-1" v-model="selectedWeek" @input="onLoadTable" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="weeks" />
            </div>
            <!-- <b-form-group> -->
            <!-- </b-form-group>
            <b-form-group> -->
            <!-- </b-form-group> -->
            <!-- <b-row style="width: 100%">
                <b-col md="3" sm="6" xs="6">
                </b-col>
                <b-col md="3" sm="6" xs="6">
                </b-col>
            </b-row> -->
            <!-- <v-select class="select_status mb-1" v-model="selectedStatus" @input="onLoadTable" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="status" />
            <xlsx-workbook>
                <xlsx-sheet :collection="items.length ? items : [{ message: 'There is no data' }]" key="Sheet1" sheet-name="Sheet1" />
                <xlsx-download>
                    <b-button variant="primary"><feather-icon icon="DownloadIcon" class="mr-50" />Export Data</b-button>
                </xlsx-download>
            </xlsx-workbook> -->

            <!-- sorting  -->
            <b-form-group label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
                <b-input-group size="md">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                    <b-button variant="info" @click="csvExport" class="ml-1">
                        <feather-icon
                            icon="DownloadIcon"
                            class="mr-50"
                        />CSV</b-button
                    >
                </b-input-group>
            </b-form-group>

            <!-- filter -->
        </div>
    </b-card-body>

    <b-table sticky-header striped hover responsive class="position-relative" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(name)="data">
            <b-link @click="onShowPayroll(data.item)" class="font-weight-bold d-block text-nowrap">
                {{ data.value }}
            </b-link>
        </template>
        <template #cell(shuttleEarnings)="data">
            {{ '$' + data.item.shuttleEarnings }}
        </template>
        <template #cell(linehaulEarnings)="data">
            {{ '$' + data.item.linehaulEarnings }}
        </template>
        <template #cell(payrate)="data">
            <b-badge variant="light-warning">
                {{ '$' + data.item.payrate }}
            </b-badge>
        </template>
        <template #cell(spots.length)="data">
            {{ data.item.spots.length}}
        </template>
        <template #cell(spotEarnings)="data">
            
                {{ '$' + data.item.spotEarnings }}
            
        </template>
        <template #cell(totalMiles)="data">
            
                {{ data.item.totalMiles }}
            
        </template>
        <template #cell(earned)="data">
            <b-badge variant="light-success">
                {{ '$' + data.item.earned }}
            </b-badge>
        </template>
        <template #cell(fixedPay)="data">
            <b-badge variant="light-primary">
                {{ '$' + data.item.fixedPay }}
            </b-badge>
        </template>
    </b-table>

</b-card>
</template>

<script>
import {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
} from "vue-xlsx/dist/vue-xlsx.es.js"
import {
    BRow,
    BCol,
    BCard,
    BLink,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardHeader,
    BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import {
    serverUri
} from '@/config'
import {
    getUserData
} from "@/auth/utils";
import { mapActions } from 'vuex';
import exportFromJSON from "export-from-json";

export default {
    components: {
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        BRow,
        BCol,
        BCard,
        BLink,
        BCardHeader,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        vSelect
    },
    data() {
        return {
            totalRows: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [{
                    key: 'driverId',
                    label: 'DRIVER ID',
                },
                {
                    key: 'name',
                    label: 'DRIVER NAME',
                    sortable: true,
                },
                {
                    key: 'shuttleMiles',
                    label: 'SHUTTLE MILES',
                    sortable: true
                },
                {
                    key: 'shuttleEarnings',
                    label: 'SHUTTLE EARNINGS',
                    sortable: true
                },
                
                {
                    key: 'linehaulMiles',
                    label: 'LH MILES',
                    sortable: true
                },
                {
                    key: 'linehaulEarnings',
                    label: 'LH EARNINGS',
                    sortable: true
                },                
                {
                    key: 'spots.length',
                    label: 'SPOT QTY',
                    sortable: true
                }, 
                {
                    key: 'spotEarnings',
                    label: 'SPOT EARNINGS',
                    sortable: true
                },
                {
                    key: 'totalMiles',
                    label: 'TOTAL MILES',
                    sortable: true
                },
                {
                    key: 'earned',
                    label: 'TOTAL EARNINGS',
                    sortable: true
                },
                {
                    key:'fixedPay',
                    label: "FIXED PAY",
                    sorteable: true,
                },
                {
                    key: 'payrate',
                    label: '$ / MILE',
                    sortable: true
                }
                
            ],
            /* eslint-disable global-require */
            items: [],
            selectedYear: null,
            selectedWeek: null,
            selectedStatus: {
                title: 'Working now',
                value: 1
            },
            years: [],
            weeks: [],
            status: [{
                title: 'Working now',
                value: 1
            }, {
                title: 'Not longer working',
                value: 0
            }],
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    created() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        this.selectedYear = currentYear;

        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

        const week = Math.ceil(days / 7);

        let dayOfWeekNumber = currentDate.getDay();
        dayOfWeekNumber >= 3 ? this.selectedWeek = week - 1 : this.selectedWeek = week - 2;

        for (let i = currentYear - 3; i <= currentYear; i++) {
            this.years.push(i);
        }

        for (let i = 1; i <= 52; i++) {
            this.weeks.push(i);
        }

        this.onLoadTable();
    },
    methods: {
        ...mapActions('payrollStore', ['addPayrollAction', 'addFRAction']),
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onShowPayroll(data) {
            this.addPayrollAction({...data, year: this.selectedYear, week: this.selectedWeek, status: this.selectedStatus.title});

            this.$router.push({ name: 'apps-payroll-driver' });
        },
        
        
        async onLoadTable() {
            this.items.splice(0, this.items.length);

            const company = getUserData().company_id;

            
            try {
                
                const response = await axios.post(`${serverUri}/api/payroll/allpayroll/${company}`, {
                    year: this.selectedYear,
                    week: this.selectedWeek,
                    status: this.selectedStatus.value
                });
                const rates = this.processRateSettings(response.data.rateSetting, response.data.spotSetting);
                const tripData = this.aggregateDataByDriver(response.data.tripData, rates, 'trip');
                const spotData = this.aggregateDataByDriver(response.data.spotData, rates, 'spot');
                this.addFRAction(rates.fixedRates);

                console.log("the trip data is", tripData)
                console.log("the spot data is", spotData)

                const fixedPayrollData = await this.fetchFixedPayrollData(company);
                this.items = this.consolidatePayrollData(tripData, spotData, fixedPayrollData);
                

            }catch(error) {
            console.error('Error fetching payroll data:', error);
            
            }

        },
        async fetchFixedPayrollData(company) {
            try {
                // Replace with your actual endpoint and required parameters
                const response = await axios.post(`${serverUri}/api/payroll/fixedPayroll/${company}`, {
                year: this.selectedYear,
                week: this.selectedWeek,
                
                });

                // Process and map the payroll data by driver_id
                const fixedPayrollMap = new Map();
                response.data.result.forEach(item => {
                    fixedPayrollMap.set(item.driver_id, {
                        driverName: item.driver_name,
                        driverId: item.driver_id,
                        ptracDriverId: item.ptrac_driver_id,
                        totalHours: item.total_hours,
                        daysWorked: item.days_worked,
                        totalPay: item.total_pay
                    });
                });
                return fixedPayrollMap;
            } catch (error) {
                console.error('Error fetching fixed payroll data:', error);
                throw error; // rethrow the error to be handled by the caller
            }
        },
        processRateSettings(fixedRates, spotRates) {
            // Normalizing fixed rate settings
            const normalizedFixedRates = fixedRates.map(rate => ({
                fromMiles: parseFloat(rate.from_miles),
                toMiles: parseFloat(rate.to_miles),
                fixedRate: parseFloat(rate.fixed_rate)
            }));

            // Normalizing spot pay settings
            const normalizedSpotRates = spotRates.map(rate => ({
                fromMiles: parseFloat(rate.from_miles),
                toMiles: parseFloat(rate.to_miles),
                spotPay: parseFloat(rate.spot_pay)
            }));

            return {
                fixedRates: normalizedFixedRates,
                spotRates: normalizedSpotRates
            };
        },
        calculateRatesForEntity(entity, rateSettings, entityType) {
            
            // Select the correct rate settings based on whether this is a trip or spot
            const applicableRateSettings = entityType === 'trip' ? rateSettings.fixedRates : rateSettings.spotRates;
            const entityMiles = entity.miles;

            // Find the rate that applies to the miles traveled
            const applicableRate = applicableRateSettings.find(rate => 
                entityMiles >= rate.fromMiles && entityMiles <= rate.toMiles
            );

            // If there's an applicable rate, use its rate; otherwise, calculate per mile
            const rate = applicableRate
                ? applicableRate.fixedRate || applicableRate.spotPay
                : entity.price_per_mile * entityMiles;
            

            return {
                ...entity, // Spread the existing entity properties
                rateApplied: applicableRate ? true : false, // Flag if a rate was applied
                earned: parseFloat(rate).toFixed(2), // The calculated rate for this entity
                isShuttle: (entityType === "trip" && applicableRate) ? true : false,
            

            };
        },

        aggregateDataByDriver(data, rateSettings, type) {
            const aggregatedData = {};
            const companyAgregated = { 
                totalMiles: 0, 
                totalPayroll: 0, 
                shuttlesCount: 0, 
                lhTripsCount: 0,
                spotsCount: 0,
                shuttlesPay: 0,
                spotsPay: 0,
                lhPay: 0,
                };
            // Process each entry in the data
            data.forEach(entity => {
                const entityWithRate = this.calculateRatesForEntity(entity, rateSettings, type);

                // If the driver has not been added to the aggregatedData object, add them
                if (!aggregatedData[entity.driver_id]) {
                    aggregatedData[entity.driver_id] = {
                        driverId: entity.driver_id,
                        payrate: entity.price_per_mile,
                        name: entity.driver_name,
                        totalMiles: 0,
                        earned: 0,
                        trips: [],
                        spots: [],
                        shuttleMiles: 0,
                        shuttleEarnings: 0,
                        linehaulMiles: 0,
                        linehaulEarnings: 0,
                        spotMiles: 0,
                        spotEarnings: 0,
                    };
                }
                const driverData = aggregatedData[entity.driver_id];
                const miles = parseFloat(entityWithRate.miles) || 0;
                const earned = parseFloat(entityWithRate.earned) || 0;

                if (entityWithRate.isShuttle) {

                    driverData.shuttleMiles += miles;
                    driverData.shuttleEarnings += earned;
                    companyAgregated.shuttlesPay += earned;
                    companyAgregated.shuttlesCount += 1;

                } else if (type === 'trip') {
                    driverData.linehaulMiles += miles;
                    driverData.linehaulEarnings += earned;
                    companyAgregated.lhPay += earned;
                    companyAgregated.lhTripsCount += 1;
                } else if (type === 'spot'){
                    driverData.spotMiles += miles;
                    driverData.spotEarnings += earned;
                    companyAgregated.spotsPay += earned;
                    companyAgregated.spotsCount += 1;
                }

                // Add miles and rate to the total for this driver
                driverData.totalMiles += miles;
                companyAgregated.totalMiles += miles;

                driverData.earned += earned;
                companyAgregated.totalPayroll += earned;

                // Add the individual trip or spot to the driver's list
                if (type === 'trip') {
                driverData.trips.push(entityWithRate);
                } 
                else if (type === 'spot'){ // type === 'spot'
                driverData.spots.push(entityWithRate);
                }
            });
            
            // Convert the aggregated data object to an array
            return Object.values(aggregatedData);
        },
        
        consolidatePayrollData(tripData, spotData, fixedPayrollData) {
  
  
            const consolidatedMap = new Map();

            // Helper function to add or update driver data in the map
            const addOrUpdateDriverData = (data, type) => {
                data.forEach(entry => {
                    const driver = consolidatedMap.get(entry.driverId) || {
                        driverId: entry.driverId,
                        name: entry.name,
                        payrate: entry.payrate,
                        totalMiles: 0,
                        earned: 0,
                        trips: [],
                        spots: [],
                        shuttleMiles: 0,
                        shuttleEarnings: 0,
                        linehaulMiles: 0,
                        linehaulEarnings: 0,
                        spotEarnings: 0,
                        fixedPay: 0,
                    };

                    driver.totalMiles += entry.totalMiles;
                    driver.earned += parseFloat(entry.earned);
                    
                    

                    if (type === 'trip') {
                        driver.trips = driver.trips.concat(entry.trips);
                        driver.shuttleMiles = entry.shuttleMiles;
                        driver.shuttleEarnings = parseFloat(entry.shuttleEarnings).toFixed(2);
                        driver.linehaulMiles = entry.linehaulMiles;
                        driver.linehaulEarnings = parseFloat(entry.linehaulEarnings).toFixed(2);
                    } 
                    else if (type === 'spot') { // type is 'spot'
                        driver.spotEarnings = parseFloat(entry.spotEarnings).toFixed(2);
                        driver.spots = driver.spots.concat(entry.spots);
                        
                    }

                    consolidatedMap.set(entry.driverId, driver);
                });
            };

            // Process trip and spot data
            addOrUpdateDriverData(tripData, 'trip');
            addOrUpdateDriverData(spotData, 'spot');

            // Convert the map to an array
            for (const [driverId, driverInfo] of fixedPayrollData.entries()) {
                const driverData = consolidatedMap.get(driverId) || {
                    driverId: driverInfo.driverId,
                    name: driverInfo.driverName, 
                    totalMiles: 0,
                    earned: 0,
                    trips: [],
                    spots: [],
                    shuttleMiles: 0,
                    shuttleEarnings: 0,
                    linehaulMiles: 0,
                    linehaulEarnings: 0,
                    spotMiles: 0,
                    spotEarnings: 0,
                    fixedPay: 0.00, 
                };

                // Add fixed pay to the driver's data
                driverData.fixedPay = parseFloat(driverInfo.totalPay).toFixed(2);
                consolidatedMap.set(driverId, driverData);
            }

            
            const consolidatedData = Array.from(consolidatedMap.values());
            console.log("Consolidated payroll data:", consolidatedData);

            return consolidatedData;
        },




        csvExport() {
            const data = this.items.map((item) => {
                return {
                    Last_Name: item.name.split(',')[0],
                    First_Name: item.name.split(',')[1],
                    SSN: item.ssn ? item.ssn : '',
                    Custom_earning_weekly_amount: item.earned,
                    Personal_note: ''
                }
            });

            const fileName = "GFT Gusto Payroll";
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
        }
    },
}
</script>

<style>
.select_status {
    width: 23%;
}

@media only screen and (max-width: 768px) {
    .select_status {
        width: 100%;
    }
}

.b-table-sticky-header {
    max-height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 2rem + 15rem ) );;
}
</style>
